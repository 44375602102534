<template>
  <div class="layout--full-page">
    <RouterView></RouterView>
  </div>
</template>

<script>
export default {
  name: 'MasterFull'
}
</script>
